import React, {useEffect, useState} from "react";
import {StyleSheet} from "../../../utils/styles";
import {baseStyles, DefaultBackground} from "../../../utils/baseStyles";
import {observer} from "mobx-react-lite";
import {PublicArticleObject} from "../../../types/materials/article/PublicArticleObject";
import {Routers} from "../../../utils/Routers";
import {PageBitoruimNames} from "../../../stores/bitoriumMenuStore";
import Link from "next/link";
import {Image} from "../../../components/baseComponents/Image";
import {Avatar, Card} from "@mui/material";
import {getImageTheme} from "../../../utils/imageTheme";
import {stores} from "../../../pages/_app";
import CBCategories from "../categories";
import {CategoriesType, Colors, FontStyle, GrayScale} from "../../enums";
import CBText from "../../baseComponent/text";

interface IProps {
	article: PublicArticleObject
}

export const CBBitoriumPopularArticle = observer(({article}: IProps) => {
	const [isDarkTheme, setTheme] = useState(false)

	useEffect(() => {
		getImageTheme(!!article.coverImage ? article.coverImage : DefaultBackground, 385, 200, (isDarkTheme) => {
			setTheme(isDarkTheme)
		})
	}, [])

	return (
		<Card style={{...styles.container, width: stores.adaptiveService.isMinTablet ? '380px' : '280px'}}
					elevation={isDarkTheme ? 0 : 2}>
			<>
				<Image src={!!article.coverImage ? article.coverImage : DefaultBackground} width={385} height={200} style={{
					...styles.backgroundImage,
					opacity: isDarkTheme ? 0.4 : 0.8,
					filter: isDarkTheme ? 'blur(3px)' : 'blur(3px)'
				}}/>
				<div style={{background: isDarkTheme ? 'black' : 'white', position: 'absolute', width: 400, height: 200}}/>
			</>
			<div style={styles.contentContainer}>
				<div style={{...baseStyles.horizontalContainer, justifyContent: 'space-between', alignItems: 'center'}}>
					<CBCategories categories={article.categories.map(item => item.title)} type={CategoriesType.MINIMAL}/>
					<Link
						href={Routers.MARKETPLACE_USER_PROFILE.replace(':userId', article.author.id.toString())}
					>
						<div style={{display: 'flex', cursor: 'pointer', columnGap: '5px', alignItems: 'center'}}>
							<Avatar sx={{height: 25, width: 25, cursor: 'pointer'}} alt="Remy Sharp"
											src={article.author.profilePhoto}/>
							<CBText style={{display: 'flex', alignItems: 'center', fontSize: !stores.adaptiveService.isMinTablet ? '12px' : 'initial'}} fontStyle={FontStyle.XSMALL} color={isDarkTheme ? Colors.PRIMARY_LIGHT : GrayScale.TITLE_ACTIVE_COLOR}>{article.author.login}</CBText>
						</div>
					</Link>
				</div>

				<div style={{flexGrow: 1, minHeight: '20px'}}/>
				<Link
					href={Routers.MARKETPLACE_BITORIUM_WITH_PARAMETER_ARTICLE.replace(':articleId', `${article.id}`)
						.replace(':pageType', PageBitoruimNames.articles)}
				>
					<a
						style={{...styles.title, color: isDarkTheme ? 'white' : 'black', textDecoration: 'none'}}
						children={article.title}
					/>
				</Link>
			</div>
		</Card>
	)
})

const styles = StyleSheet.create({
	container: {
		position: 'relative',
		backgroundColor: 'white',
		borderRadius: '20px',
		width: '380px',
		height: '190px',
		margin: '5px'
	},
	contentContainer: {
		...baseStyles.verticalContainer,
		zIndex: 2,
		position: 'relative',
		padding: '20px',
		display: 'flex',
		height: '90%'
	},
	backgroundImage: {
		filter: 'blur(3px)',
		position: 'absolute',
		zIndex: 1,
		top: '-5px',
		left: '-5px',
		overflow: 'hidden',
		opacity: '0.4'
	},
	title: {
		fontFamily: 'Montserrat',
		fontWeight: '600',
		fontSize: '13px',
		marginBottom: '30px',
		textTransform: 'uppercase',
		textOverflow: "ellipsis",
		overflow: "hidden",
		maxHeight: '55px',
		lineHeight: '18px',
		letterSpacing: '0.8px',
	},
})
