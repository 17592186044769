import {Box, Checkbox} from '@mui/material';
import React, {CSSProperties, useEffect, useState} from 'react';
import {CategoriesType, Colors, ComponentSize, ComponentType, FontStyle, FontType, GrayScale, ViewsAndCommentsType} from '../../enums';
import CBText from '../../baseComponent/text';
import {PublicArticleObject} from '../../../types/materials/article/PublicArticleObject';
import CBCategories from '../categories';
import CBButton from '../../baseComponent/buttons/mainButton';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import {CBAvatar} from '../../baseComponent/avatar';
import {StyleSheet} from "../../../utils/styles";
import {useRouter} from 'next/router';
import {DefaultBackground} from '../../../utils/baseStyles';
import {observer} from 'mobx-react-lite';
import {stores} from '../../../pages/_app';
import {Routers} from '../../../utils/Routers';
import {PageBitoruimNames} from '../../../stores/bitoriumMenuStore';
import Link from 'next/link';
import Image from 'next/image';
import {getImageTheme} from '../../../utils/imageTheme';
import ViewsAndComments from '../commentViewsAndRating';

interface IProps {
  data: PublicArticleObject,
  style?: CSSProperties,
}

export const CBMaterialItem = observer(({style, data}: IProps) => {
  const router = useRouter()

  const [isDarkTheme, setTheme] = useState(false)

  useEffect(() => {
    if (!data.coverImage) {
      setTheme(false)
      return
    }
    getImageTheme(!!data.coverImage ? data.coverImage : DefaultBackground, 780, 390, (isDarkTheme) => {
      setTheme(isDarkTheme)
    })
  }, [])

  const formatingDate = (date: string) => {
    const _date = new Date(date).toLocaleDateString('ru-RU')
    const _time = new Date(date).toLocaleTimeString('ru-RU', {hour: "2-digit", minute: "2-digit"})
    return _date + " " + _time
  }

  const renderArticleHeader = () => {
    return (
      <>
        <CBCategories type={stores.adaptiveService.isMobile ? CategoriesType.MINIMAL : CategoriesType.SHORT} style={{maxWidth: '356px'}} categories={data.tags ? data.tags.split('|') : null} />
        {!stores.adaptiveService.isMobile && <Box sx={{display: 'flex', alignItems: 'start'}}>
          <CBButton
            style={{marginTop: '-7px'}}
            type={ComponentType.TEXT}
            size={ComponentSize.SMALL}
            onClick={() => router.push(Routers.MARKETPLACE_BITORIUM_WITH_PARAMETER_ARTICLE.replace(':articleId', `${data.id}`)
            .replace(':pageType', PageBitoruimNames.articles))}>
              Подробнее
          </CBButton>
          <Checkbox
            sx={{padding: '7px',}}
            icon={<StarBorderIcon fontSize={'inherit'} sx={{fontSize: '15px', color: isDarkTheme ? GrayScale.LINE_COLOR : 'inherit'}} />}
            checkedIcon={<StarIcon fontSize={'inherit'} sx={{fontSize: '15px', color: Colors.WARNING}} />}
          />
        </Box>}
      </>
    )
  }

  const renderArticleBody = () => {
    return (
      <>
        <Link passHref href={Routers.MARKETPLACE_BITORIUM_WITH_PARAMETER_ARTICLE.replace(':articleId', `${data.id}`)
          .replace(':pageType', PageBitoruimNames.articles)}>
          <a style={{textDecoration: 'none'}}>
            <CBText
              style={styles.title}
              color={isDarkTheme? GrayScale.INPUT_BACKGROUND : GrayScale.TITLE_ACTIVE_COLOR}
              type={stores.adaptiveService.isMobile ? FontType.TEXT : FontType.TITLE}
              fontStyle={stores.adaptiveService.isMobile ? FontStyle.MEDIUM : FontStyle.SMALL}>
                {data.title}
            </CBText>
          </a>
        </Link>
        <CBText
          style={styles.description}
          color={isDarkTheme? GrayScale.PLACEHOLDER_COLOR : GrayScale.BODY_COLOR}
          type={FontType.TEXT}
          fontStyle={stores.adaptiveService.isMobile ? FontStyle.SMALL : FontStyle.MEDIUM}>
            {data.description}
        </CBText>
      </>
    )
  }

  const renderArticleFooter = () => {
    return (
      <>
        <Box sx={{display: 'flex', flexDirection: 'row', marginBottom: stores.adaptiveService.isMobile ? '10px' : '0'}}>
          <Link href={Routers.MARKETPLACE_USER_PROFILE.replace(':userId', data.author.id.toString())}>
            <a><CBAvatar url={data.author.profilePhoto} /></a>
          </Link>
          <Box sx={{display: 'flex', flexDirection: 'column', marginLeft: '10px'}}>
            <CBText
              type={FontType.TEXT}
              fontStyle={FontStyle.SMALL}
              color={isDarkTheme? GrayScale.INPUT_BACKGROUND : GrayScale.BODY_COLOR}>
                {data.author.login}
            </CBText>
            <CBText
              type={FontType.TEXT}
              fontStyle={FontStyle.XSMALL}
              color={isDarkTheme? GrayScale.PLACEHOLDER_COLOR : GrayScale.LABEL_COLOR}>
                {formatingDate(data.createDate)}
            </CBText>
          </Box>
        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width: stores.adaptiveService.isMobile ? '100%' : '50%',
          justifyContent: stores.adaptiveService.isMobile ? 'space-between' : 'end'
        }}>
          <ViewsAndComments
            type={ViewsAndCommentsType.VIEWS_AND_COMMENTS_RATING}
            views={data.views}
            comments={data.commentsCount}
            rating={data.rating.average} />
        </Box>
      </>
    )
  }

  return (
    <Box
      style={style}
      sx={styles.container}>
        {data.coverImage && <Image
          src={data.coverImage}
          layout={'fill'}
          objectFit={'cover'}
          objectPosition={'center'}
          style={{...styles.backgroundImage, filter: isDarkTheme ? 'blur(8px)' : 'blur(10px)',}}/>
        }
        <Box sx={{
          ...styles.content,
          backgroundColor: isDarkTheme ? 'rgba(20, 37, 43, 0.8)' : 'rgba(252, 252, 252, 0.85)',
        }}>
          <Box sx={{...styles.header, justifyContent: data.categories.length !== 0 ? 'space-between' : 'end'}}>{renderArticleHeader()}</Box>
          <Box sx={styles.body}>{renderArticleBody()}</Box>
          <Box sx={{
            ...styles.footer,
            flexDirection: stores.adaptiveService.isMobile ? 'column' : 'row',
            alignItems: stores.adaptiveService.isMobile ? 'start' : 'end',
          }}>
            {renderArticleFooter()}
          </Box>
        </Box>
    </Box>
  )
})

const styles = StyleSheet.create({
  container: {
    display: 'block',
    width: '100%',
    marginBottom: '30px',
    position: 'relative',
    border: '1px solid' + GrayScale.LINE_COLOR,
    borderRadius: '20px',
    overflow: 'hidden',
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
    width: '100%',
    zIndex: 3,
    height: '100%'
  },

  header: {
    display: 'flex',
    position: 'relative',
    verticalAlign: 'top',
    padding: '20px 20px 0 20px',
    zIndex: 4,
    opacity: 1,
  },

  body: {
    minHeight: '180px',
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    justifyContent: 'end',
    padding: '20px',
    zIndex: 4,
    opacity: 1,
  },

  footer: {
    display: 'flex',
    flexWrap: 'wrap',
    position: 'relative',
    justifyContent: 'space-between',
    padding: '0 20px 20px 20px',
    zIndex: 4,
    opacity: 1,
  },

  backgroundImage: {
    position: 'relative',
    zIndex: 2,
  },

  title: {
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '1',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
  },

  description: {
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '2',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
  },
})
