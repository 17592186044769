import React from 'react';
import {Rating, Stack} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import {Colors, GrayScale, ViewsAndCommentsType} from "../../enums";
import {defaultFontStyles} from "../../fontStyle";
import CBText from "../../baseComponent/text";

interface IProps {
	views: number,
	comments: number,
	rating?: number,
	numberOfRatings?: number,
	type?: ViewsAndCommentsType,
	color?: string,
	fontSize?: string | number,
	colorExtends?: GrayScale | Colors,
	readonly?: boolean,
	onChange?: (value: number) => void,
	ratingAverage?: number,
}

const ViewsAndComments = ({views, comments, rating = 4.5, numberOfRatings = 0, type = ViewsAndCommentsType.VIEWS_AND_COMMENTS, color = GrayScale.PLACEHOLDER_COLOR, fontSize, colorExtends = Colors.LABEL_COLOR, readonly = true, onChange, ratingAverage}: IProps) => {
	const isInt = (num: number): boolean => {
		return num % 1 === 0;
	}

	const convertRating = (rating): string => {
		if (isInt(rating)) {
			return String(rating);
		} else {
			return String(rating).at(0) + ',' + String(rating).at(-1);
		}
	}

	const numberDeclination = (number: number, titles: string[]): string => {
		const cases = [2, 0, 1, 1, 1, 2];

		return titles[number % 100 > 4 && number % 100 < 20
			? 2
			: cases[number % 10 < 5 ? number % 10 : 5]
			];
	}

	const renderViewsAndComments = (): JSX.Element => (
		<>
			<Stack direction="row" spacing={0.5} sx={{alignItems: 'center'}}>
				<VisibilityIcon sx={{width: '15px', height: '15px'}}/>
				<CBText style={{...defaultFontStyles.textXSmall, fontSize: fontSize ? fontSize : 'auto'}} color={color as GrayScale}>{String(views)}</CBText>
			</Stack>
			<Stack direction="row" spacing={0.5} sx={{alignItems: 'center'}}>
				<MessageOutlinedIcon sx={{width: '15px', height: '15px'}}/>
				<CBText style={{...defaultFontStyles.textXSmall, fontSize: fontSize ? fontSize : 'auto'}} color={color as GrayScale}>{String(comments)}</CBText>
			</Stack>
		</>
	)

	const renderFullRating = (): JSX.Element => (
		<Stack direction="row" spacing={0.5} sx={{alignItems: 'center'}}>
			<Rating name="half-rating-read" value={rating} precision={0.5} readOnly={readonly}
							sx={{color: Colors.SUCCESS, columnGap: '3px', fontSize: '20px'}}
							emptyIcon={<StarBorderIcon sx={{color: Colors.SUCCESS, fontSize: 'inherit'}}/>}
							onChange={(!readonly && onChange) ? (e, value) => onChange(value) : undefined}/>
		</Stack>
	)
	const renderShortRating = (): JSX.Element => (
		<Stack direction="row" spacing={0.1} sx={{alignItems: 'center'}}>
			<CBText style={{...defaultFontStyles.textXSmall, fontSize: fontSize ? fontSize : 'auto'}} color={color as GrayScale}>{String(rating)}</CBText>
			{
				rating === 0
					?
					<StarBorderIcon sx={{
						fontSize: '20px',
						columnGap: '3px',
						color: Colors.SUCCESS
					}}/>
					:
					<StarIcon sx={{
						fontSize: '20px',
						columnGap: '3px',
						color: Colors.SUCCESS
					}}/>
			}
		</Stack>
	)

	const renderBottomExtends = (): JSX.Element => (
		<CBText style={{
			...defaultFontStyles.textXSmall
		}} color={colorExtends as GrayScale | Colors}>{`Средняя оценка ${convertRating(ratingAverage ? ratingAverage : rating)} (всего ${numberOfRatings + ' ' + numberDeclination(numberOfRatings, ['оценка', 'оценки', 'оценок'])})`}</CBText>
	)

	return (
		<Stack spacing={0.5}>
			<Stack justifyContent={type === ViewsAndCommentsType.ONLY_RATING ? 'flex-end' : 'flex-start'} direction="row" spacing={1.4}
						 fontStyle={{...defaultFontStyles.textXSmall, color}}>

				{(type === ViewsAndCommentsType.VIEWS_AND_COMMENTS_RATING || type === ViewsAndCommentsType.DEFAULT_WITH_EXTEND_RATING || type === ViewsAndCommentsType.ONLY_RATING) && renderFullRating()}

				{(type === ViewsAndCommentsType.SHORT || type === ViewsAndCommentsType.SHORT_WITH_EXTEND_RATING) && renderShortRating()}

				{type !== ViewsAndCommentsType.ONLY_RATING && renderViewsAndComments()}
			</Stack>

			{(type === ViewsAndCommentsType.SHORT_WITH_EXTEND_RATING || type === ViewsAndCommentsType.DEFAULT_WITH_EXTEND_RATING || type === ViewsAndCommentsType.ONLY_RATING) && renderBottomExtends()}
		</Stack>
	);
};

export default ViewsAndComments;
