import React, {ChangeEvent, useEffect, useState} from 'react';
import Head from "next/head";
import {StyleSheet} from "../utils/styles";
import {GrayScale} from "../uikit/enums";
import {stores} from "../pages/_app";
import Carousel from "react-multi-carousel";
import {Box, Skeleton, Pagination} from "@mui/material";
import {CBMaterialItem} from "../uikit/components/articleItem/materialItem";
import {observer} from "mobx-react-lite";
import {PageNames} from "../stores/menuHeaderStore";
import {PageBitoruimNames} from "../stores/bitoriumMenuStore";
import {isClient} from "../utils/isServerCheck";
import {Routers} from "../utils/Routers";
import {PageProfileNames} from "../stores/MenuProfileStore";
import {TypeOfAuth} from "../types/auth/TypeOfAuth";
import {useRouter} from "next/router";
import CBButton from "../uikit/baseComponent/buttons/mainButton";
import {CBBitoriumPopularArticle} from "../uikit/components/articleItem/bitoriumPopularArticle";
import CBBannerAdvertising from "../uikit/components/bannerAdvertising";
import CBText from "../uikit/baseComponent/text";
import {baseStyles} from "../utils/baseStyles";
import SearchMaterials from "../uikit/components/searchMaterials";

const responsive = {
  desktopMax: {
    breakpoint: {max: 3000, min: 1650},
    items: 4,
    partialVisibilityGutter: 30
  },
  desktop: {
    breakpoint: {max: 1650, min: 1300},
    items: 3,
    partialVisibilityGutter: 40
  },
  tablet: {
    breakpoint: {max: 1300, min: 1000},
    items: 2,
    partialVisibilityGutter: 60
  },
  tabletMin: {
    breakpoint: {max: 1000, min: 768},
    items: 2
  },
  mobile: {
    breakpoint: {max: 768, min: 0},
    items: 1,
    partialVisibilityGutter: 10
  }
};

const IndexPage = observer(() => {
  const router = useRouter();
  const [_isClient, setIsClient] = useState(false);
  const [page, setPage] = useState<number>(1)
  const [_, setSortValue] = useState(3)

  useEffect(() => {
    stores?.bitoriumStore.getData()
    stores?.menuHeaderStore.setCurrentPage(PageNames.bitorium)
    stores?.menuBitoriumStore.setCurrentPage(PageBitoruimNames.articles)
    setIsClient(isClient)
  }, [])

  const renderPopularSkeleton = () => (
    <Skeleton variant='rectangular'
              sx={{borderRadius: '20px'}}
              width={stores.adaptiveService.isMinTablet ? '380px' : '280px'}
              height={190}/>
  )

  const renderPopularMaterial = () => (
    <Box sx={styles.popular}>
      <CBText style={{
        ...styles.title,
        fontSize: stores.adaptiveService.isMinDesktopMini ? '32px' : '20px',
        marginBottom: stores.adaptiveService.isMinDesktopMini ? '15px' : '10px'
      }}>Популярные материалы</CBText>

      <Carousel responsive={responsive}
                ssr
                partialVisible={true}
                itemClass={stores.adaptiveService.isMinTablet ? 'carouselItem' : 'carouselItemMobile'}
                containerClass={'carouselContainer'}>
        {
          stores.bitoriumStore.isLoadingData
            ? Array(3).fill(renderPopularSkeleton())
            : stores?.homeLandingStore.popularArticles.map((article, index) => <CBBitoriumPopularArticle
              key={article.id} article={article}/>)
        }
      </Carousel>
    </Box>
  )

  const renderSearchedMaterialSkeleton = () => (
    <Skeleton variant='rectangular'
              sx={{borderRadius: 2, marginBottom: '20px'}}
              width={'100%'}
              height={256}
    />
  )

  const renderWriteArticleBtn = () => (
    <CBButton style={{width: '100%', marginBottom: !stores.adaptiveService.isMinDesktop ? '20px' : '0'}}
              onClick={() => {
                stores.authStore.isUserAuth
                  ? router.push(Routers.PERSONAL_PROFILE_WITH_PARAMETER.replace(":pageType", PageProfileNames.authorArticles))
                  : stores.menuHeaderStore.setIsAuthShow(TypeOfAuth.signUpByEmail, true)
              }}>Опубликовать статью</CBButton>
  )

  const handleChangePage = (event: ChangeEvent<unknown>, value: number) => {
    setPage(value)
    stores?.bitoriumStore.getSortedArticle('page', value - 1)
  }

  const renderArticles = () => (
    <div style={styles.articles}>
      {!stores.adaptiveService.isMinDesktop && renderWriteArticleBtn()}
      {
        !stores?.bitoriumStore.isLoaded
          ? stores?.bitoriumStore.sortedArticle.articles.map((article, index) => <CBMaterialItem key={article.id} data={article}/>)
          : Array(3).fill(renderSearchedMaterialSkeleton())
      }
      {stores?.bitoriumStore.sortedArticle.articles.length === 0 &&
        <CBText>Статьи по вашему запросу не найдены, измените данные для поиска</CBText>}

      <Pagination
        count={stores?.bitoriumStore.sortedArticle.countPages}
        sx={styles.pagination}
        page={page}
        onChange={handleChangePage}
      />
    </div>
  )

  return (
    <div style={{
      ...styles.main,
      paddingBottom: stores.adaptiveService.isMinDesktop ? 40 : 10,
      paddingTop: stores.adaptiveService.isMinDesktop ? 40 : 10
    }}>
      <Head>
        <meta property="og:type" content="website"/>
        <title>Биториум COURSEBIT | Статьи</title>
        <meta property='og:title' content='Биториум COURSEBIT | Статьи'/>
        <meta name="description"
              content="Coursebit — образовательная платформа дистанционного обучения для авторов и студентов"/>
        <meta property={'keywords'} content={'тестирование,machine learning,курсы python,scala,обучение python,spring framework,java для начинающих,хакинг,project manager,тестирование мобильных приложений,разработка игр,курсы c,курсы java,линукс для начинающих,sql для начинающих,компьютерные науки,software testing,software development,cisco курсы,online courses,программинг'}/>
        <meta property="og:url" content={`https://coursebit.ru/`}/>
      </Head>

      <div style={{marginLeft: stores.adaptiveService.horizontalMarginInPage}}>
        {renderPopularMaterial()}
      </div>
      <div style={{
        ...styles.containerMain,
        marginLeft: stores.adaptiveService.horizontalMarginInPage,
        marginRight: stores.adaptiveService.horizontalMarginInPage
      }}>
        <CBText style={{
          ...styles.title,
          marginTop: stores.adaptiveService.isMinDesktopMini ? '30px' : '20px',
          fontSize: stores.adaptiveService.isMinDesktopMini ? '32px' : '20px',
          marginBottom: stores.adaptiveService.isMinDesktopMini ? '30px' : '10px'
        }}>Все материалы</CBText>
        <div style={styles.containerMainInner}>
          <section style={styles.section}>
            {_isClient && <SearchMaterials sortFunc={stores?.bitoriumStore.getSortedArticle}/>}
            {renderArticles()}
          </section>
          {stores.adaptiveService.isMinDesktop &&
            <aside style={styles.aside}>
              {renderWriteArticleBtn()}
              {!stores.authStore.isUserAuth && <CBBannerAdvertising onClickEvent={() => stores.menuHeaderStore.setIsAuthShow(TypeOfAuth.signUpByEmail, true)} style={{width: '100%'}}/>}
            </aside>
          }
        </div>
      </div>
    </div>
  );
});

const styles = StyleSheet.create({
  main: {
    backgroundColor: GrayScale.BACKGROUND_COLOR,
    paddingBottom: '40px'
  },
  containerMain: {},
  containerMainInner: {
    display: 'flex',
    columnGap: '110px'
  },
  title: {
    color: '#383838',
    flexWrap: "wrap",
    fontFamily: 'Montserrat',
    fontSize: '32px',
    fontWeight: '700',
    width: '100%',
  },
  popular: {
    '& .carouselContainer': {
      marginLeft: '0'
    },
    '& .carouselContainer>ul': {
      columnGap: '20px'
    },
    '& .react-multiple-carousel__arrow': {
      zIndex: '100'
    }
  },
  section: {
    width: '100%',
    flex: '3',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '40px'
  },
  searchedMaterials: {
    display: 'flex',
    columnGap: '10px',
    rowGap: '10px',
  },
  inputSearch: {
    width: '100%',
    background: GrayScale.OFF_WHITE_COLOR,
    border: `1px solid ${GrayScale.LINE_COLOR}`,
    '&>label': {
      fontSize: '20px',
    },
    '&:has(div.MuiInputBase-colorSuccess)': {
      backgroundColor: 'initial',
      border: 'auto',
    },
    '& label.Mui-focused': {
      fontSize: 'initial'
    },
    '&:has(div.MuiInputBase-colorSuccess)>label': {
      color: GrayScale.PLACEHOLDER_COLOR,
      top: '10px',
      fontSize: '16px'
    },
    '& .MuiFormLabel-root': {
      top: '25px',
      marginLeft: '50px',
      transition: 'all 0.1s ease-in-out'
    },
    '& .MuiInputBase-root': {
      flexWrap: 'nowrap',
      paddingLeft: '24px'
    },
    '& .MuiInputBase-root>input': {
      paddingTop: '13px',
      paddingBottom: '13px'
    }
  },
  aside: {
    ...baseStyles.bitoriumAsideContainer,
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'flex-start',
    rowGap: '40px',

  },
  articles: {
    display: 'flex',
    flexDirection: 'column',
  },
  searchedInner: {
    display: 'flex',
    columnGap: '10px',
    rowGap: '10px',
    maxWidth: '520px',
    width: '100%',
  },
  sortDropdown: {
    flex: '1',
    minWidth: '195px',
    maxWidth: '270px',
    width: '100%'
  },
  categoryDropdown: {
    flex: '1',
    minWidth: '195px',
    maxWidth: '245px',
    width: '100%'
  },
  pagination: {
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center'
  }
})

export default IndexPage;
