import {Avatar, Badge, ButtonBase, styled} from "@mui/material";
import React, {CSSProperties, useCallback, useMemo} from "react";
import {Colors} from "../../enums";
import {StyleSheet} from "../../../utils/styles";

interface IProps {
  url?: string,
  name?: string,
  isOnline?: boolean,
  onClick?: () => void,
  style?: CSSProperties
}

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: Colors.PRIMARY_COLOR,
    color: Colors.PRIMARY_COLOR,
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

export const CBAvatar = ({url, name, isOnline = false, onClick, style}: IProps) => {

  const renderAvatarWithoutOnline = useMemo(() => (
    <Avatar
      alt={name}
      src={url}
      children={!!name ? `${name.substring(0, 2)}` : undefined}
      sx={{...styles.avatar, ...style}}
    />
  ), [name, url])

  const renderWithOnline = useMemo(() => (
    <StyledBadge
      overlap="circular"
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      variant="dot"
    >
      {renderAvatarWithoutOnline}
    </StyledBadge>
  ), [isOnline])

  const renderAvatar = () => isOnline ? renderWithOnline : renderAvatarWithoutOnline

  return !!onClick
    ? (
      <ButtonBase sx={{borderRadius: '100px'}}>
        {renderAvatar()}
      </ButtonBase>
    )
    : renderAvatar()
}

const styles = StyleSheet.create({
  avatar: {
    width: 48,
    height: 48,
    bgcolor: Colors.PRIMARY_COLOR
  }
})
