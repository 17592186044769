export const getImageTheme = (image: string, width: number, height: number, callback: (isDarkTheme: boolean) => void) => {
  const img = document.createElement("img");
  img.src = image
  img.crossOrigin = "Anonymous";
  img.style.display = "none";
  document.body.appendChild(img);
  let colorSum = 0;

  img.onload = () => {
    const canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;

    const ctx = canvas.getContext("2d");
    ctx.drawImage(img,0,0);

    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const data = imageData.data;
    let r,g,b,avg;

    for(let x = 0, len = data.length; x < len; x+=4) {
      r = data[x];
      g = data[x+1];
      b = data[x+2];

      avg = Math.floor((r+g+b)/3);
      colorSum += avg;
    }
    callback(Math.floor(colorSum / (width*height)) < 127.5)
  }
}