import {StyleSheet} from "../../../utils/styles";
import React from 'react';
import {Box, Stack} from "@mui/material";
import CBText from "../../baseComponent/text";
import {Image} from "../../../components/baseComponents/Image";

const BannerImage: string = '/bannerImages/defaultBannerImage.png';

interface IProps {
	type?: 'first' | 'second' | 'third',
	style?: React.CSSProperties,
	onClickEvent?: () => void
}

const CBBannerAdvertising = ({type = 'first', style, onClickEvent}: IProps) => {
	const renderFirstType = (): JSX.Element => (
		<>
			<Image src={BannerImage} alt="get_bonus_free" style={styles.imageStandard} height={430} width={313}/>

			<Stack spacing={1} sx={{
				...styles.containerInner,
				left: '24px',
				top: '86px'
			}}>
				<Stack>
					<CBText style={styles.title}>Забери бонус</CBText>
					<CBText style={styles.titleSecondary}>Бесплатно</CBText>
				</Stack>
				<Stack>
					<CBText style={styles.additional}>100 МБ хранилища</CBText>
					<CBText style={styles.additional}>10 обучающихся</CBText>
				</Stack>
				<Box sx={styles.firstButtonContainer}>
					<CBText style={styles.fontGradient}>Забрать</CBText>
				</Box>
			</Stack>
		</>
	)

	const renderSecondType = (): JSX.Element => (
		<>
			<Image src={BannerImage} alt="get_bonus_free" style={styles.imageBorderBox} height={430} width={313}/>

			<Box sx={styles.secondVector}/>

			<Stack spacing={1} sx={{
				...styles.containerInner,
				left: '22px',
				top: '73px'
			}}>
				<Stack>
					<CBText style={{...styles.title, color: '#0497B8'}}>Забери бонус</CBText>
					<CBText style={{
						...styles.titleSecondary,
						background: 'linear-gradient(263.67deg, #026EE4 23.77%, #00FFB2 142.44%)',
						backgroundClip: 'text',
						WebkitBackgroundClip: 'text',
						WebkitTextFillColor: 'transparent'

					}}>Бесплатно</CBText>
				</Stack>
				<Stack>
					<CBText style={{...styles.additional, color: '#005A8C'}}>100 МБ хранилища</CBText>
					<CBText style={{...styles.additional, color: '#005A8C'}}>10 обучающихся</CBText>
				</Stack>
				<Box sx={styles.secondButtonContainer}>
					<CBText style={{
						...styles.fontGradient,
						letterSpacing: '0.4966px',
						paddingLeft: '11px',
						fontWeight: '700',
					}}>Забрать сейчас</CBText>
				</Box>
			</Stack>
		</>
	)

	const renderThirdType = (): JSX.Element => (
		<>
			<Image src={BannerImage} alt="get_bonus_free" style={styles.imageStandard} height={430} width={313} />

			<Stack spacing={1} sx={{
				...styles.containerInner,
				left: '24px',
				top: '86px'
			}}>
				<Stack sx={{
					position: 'relative',
					top: '-7px'
				}}>
					<CBText style={styles.title}>Забери бонус</CBText>
					<CBText style={styles.titleSecondary}>Бесплатно</CBText>
				</Stack>
				<Stack sx={styles.thirdTextBackground}>
					<CBText
						style={{...styles.additional, color: type === 'third' && '#046499', paddingLeft: '41px', lineHeight: '27px'}}>100
						МБ хранилища</CBText>
					<CBText
						style={{...styles.additional, color: type === 'third' && '#046499', paddingLeft: '41px', lineHeight: '27px'}}>10
						обучающихся</CBText>
				</Stack>
				<Box sx={styles.thirdButtonContainer}>
					<CBText style={{
						...styles.fontGradient,
						letterSpacing: '1.468966px',
						paddingLeft: '11px'
					}}>Хочу получить</CBText>
				</Box>
			</Stack>
		</>
	)

	return (
		<Box
			onClick={onClickEvent}
			sx={{
			...styles.mainContainer,
			background: type === 'second' ? 'linear-gradient(203.7deg, #005EEC 0%, #07FFD2 100%)' :  'linear-gradient(203.7deg, #005EEC 0%, #13FF9C 100%)',
			border: type === 'second' && '2px dashed #FFF',
			boxSizing: type === 'second' && 'border-box',
			...style
		}}>

			{type === 'first' && renderFirstType()}
			{type === 'second' && renderSecondType()}
			{type === 'third' && renderThirdType()}

		</Box>
	);
};

const styles = StyleSheet.create({
	mainContainer: {
		width: 310,
		height: 327,
		borderRadius: '10px',
		position: 'relative',
		overflow: 'hidden',
		cursor: 'pointer',
	},
	title: {
		color: '#FFF',
		fontFamily: 'Montserrat',
		fontStyle: 'normal',
		fontWeight: '400',
		fontSize: '15px',
		lineHeight: '18px',
		letterSpacing: '0.468966px',
		textTransform: 'uppercase',
	},
	titleSecondary: {
		color: '#FFF',
		fontFamily: 'Montserrat',
		fontStyle: 'normal',
		fontWeight: '600',
		fontSize: '25px',
		lineHeight: '30px',
		letterSpacing: '0.468966px',
		textTransform: 'uppercase',
	},
	additional: {
		color: '#FFF',
		fontFamily: 'Montserrat',
		fontStyle: 'normal',
		fontWeight: '400',
		fontSize: '16px',
		lineHeight: '24px',
		letterSpacing: '0.468966px',
		textTransform: 'uppercase',
	},
	fontGradient: {
		fontFamily: 'Montserrat',
		fontStyle: 'normal',
		fontWeight: '500',
		fontSize: '16px',
		lineHeight: '20px',
		letterSpacing: '0.468966px',
		textTransform: 'uppercase',
		background: 'linear-gradient(261.19deg, #026BE6 0.55%, #00E88B 143.84%)',
		backgroundClip: 'text',
		WebkitBackgroundClip: 'text',
		WebkitTextFillColor: 'transparent'
	},
	imageStandard: {
		width: '313px',
		height: '430px',
		position: 'absolute',
		left: '195px',
		top: '-47px',
		transform: 'rotate(-27.86deg)'
	},
	containerInner: {
		width: '280px',
		height: '156px',
		position: 'absolute',
	},
	imageBorderBox: {
		width: '313px',
		height: '430px',
		position: 'absolute',
		left: '190px',
		top: '-103px',
		transform: 'rotate(-32.86deg)'
	},
	firstButtonContainer: {
		marginTop: '20px !important',
		width: '195px',
		height: '35px',
		backgroundColor: '#FFF',
		borderRadius: '20px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	secondButtonContainer: {
		marginTop: '20px !important',
		width: '239px',
		height: '41px',
		backgroundColor: '#FFF',
		borderRadius: '10px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		position: 'absolute',
		top: '161px',
		left: '-44px'
	},
	thirdButtonContainer: {
		marginTop: '20px !important',
		width: '239px',
		height: '35px',
		backgroundColor: '#FFF',
		borderRadius: '10px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		position: 'absolute',
		top: '104px',
		left: '-41px'
	},
	thirdTextBackground: {
		background: '#FFF',
		width: '239px',
		height: '54px',
		borderRadius: '10px',
		position: 'absolute',
		left: '-41px',
		top: '42px'
	},
	secondVector: {
		position: 'absolute',
		top: '-39px',
		left: '-89.5px',
		width: '272.5px',
		height: '242.5px',
		background: '#FFF',
		boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
		transform: 'skew(27deg, -10deg)'
	}
})

export default CBBannerAdvertising;