import React, {useState} from 'react';
import {stores} from "../../../pages/_app";
import {MenuItem} from "@mui/material";
import CBInput from "../../baseComponent/input/input";
import {CBDropdown} from "../../baseComponent/dropdown";
import {StyleSheet} from "../../../utils/styles";
import {ComponentSize, GrayScale} from "../../enums";
import SearchIcon from "@mui/icons-material/Search";

interface IProps {
	sortFunc: (type: 'category' | 'search' | 'sort' | 'page', value: number | string | null) => void;
}

const SearchMaterials = ({sortFunc}: IProps) => {
	const [page, setPage] = useState<number>(2)
	const handleSearch = (value) => sortFunc('search', value)

	const categoryFormat = (): string[] => {
		const arr: string[] = stores?.mainStore.category.map(item => item.parentCategories);
		let newArr: string[] = [];
		for (let i = 1; i < arr.length; i++) {
			if (!newArr.includes(arr[i])) {
				newArr.push(arr[i])
			}
		}
		return newArr;
	}

	const renderMenuItemByCategory = (category: string): JSX.Element[] => {
		const arr: JSX.Element[] = [];
		stores?.mainStore.category.map(item => item.parentCategories === category && arr.push(<MenuItem value={item.title}
																																																		key={item.id}>{item.title}</MenuItem>))
		return arr;
	}

	const handleSelectChange = (value) => {
		const foundSortId: number = stores.mainStore.sorts.find((item) => item.title === value).id
		sortFunc('sort', foundSortId);
		setPage(1)
	}

	const handleChangeCategory = (value) => {
		if (value === 'Категория') {
			sortFunc('category', '');
			setPage(1)
			return
		}
		sortFunc('category', stores.mainStore.category.find(item => item.title === value).id);
		setPage(1)
	}

	const renderSearchInput = () => (
		<CBInput style={{...styles.inputSearch}} label={'Поиск'} onChange={(value) => handleSearch(value)}
						 required={false} size={ComponentSize.MEDIUM}
						 leftIcon={<SearchIcon sx={{width: '30px', height: '30px'}}/>}
		/>
	)

	const renderCategoriesDropdown = () => {
		let categoriesArr: JSX.Element[] = [];

		categoryFormat().map((category, index) => {
			categoriesArr.push(<MenuItem disabled={true} sx={{marginLeft: '-10px'}} key={category}>{category}</MenuItem>)
			categoriesArr.push(...renderMenuItemByCategory(category))
		})

		return (
			<CBDropdown placeholderDisabled={false} placeholder='Категория' defaultValue='Категория' style={{
				...styles.categoryDropdown,
				maxWidth: !stores.adaptiveService.isMinTablet ? '100%' : 'initial'
			}} onChange={(value) => handleChangeCategory(value)}>
				{categoriesArr.map((element) => element)}
			</CBDropdown>)
	}

	const renderSortDropdown = () => (
		<CBDropdown
			style={{...styles.sortDropdown, maxWidth: !stores.adaptiveService.isMinTablet ? '100%' : 'initial'}}
			placeholder='Сортировать по'
			defaultValue='Сортировать по'
			onChange={(value) => handleSelectChange(value)}
		>
			{
				stores?.mainStore.sorts.map((item) => (
					<MenuItem value={item.title} key={item.id}>{item.title}</MenuItem>
				))
			}
		</CBDropdown>
	)

	const renderSearchedMaterials = () => (
		<div
			style={{...styles.searchedMaterials, flexDirection: !stores.adaptiveService.isMinDesktopMini ? 'column' : 'row'}}>
			{renderSearchInput()}

			<div style={{
				...styles.searchedInner,
				flexDirection: !stores.adaptiveService.isMinTablet ? 'column' : 'row',
				maxWidth: !stores.adaptiveService.isMinTablet ? '100%' : 'initial'
			}}>
				{renderCategoriesDropdown()}
				{renderSortDropdown()}
			</div>
		</div>
	)
	return renderSearchedMaterials()
};

const styles = StyleSheet.create({
	searchedMaterials: {
		display: 'flex',
		columnGap: '10px',
		rowGap: '10px',
	},
	searchedInner: {
		display: 'flex',
		columnGap: '10px',
		rowGap: '10px',
		maxWidth: '520px',
		width: '100%',
	},
	inputSearch: {
		width: '100%',
		background: GrayScale.OFF_WHITE_COLOR,
		border: `1px solid ${GrayScale.LINE_COLOR}`,
	},
	sortDropdown: {
		flex: '1',
		minWidth: '195px',
		maxWidth: '270px',
		width: '100%'
	},
	categoryDropdown: {
		flex: '1',
		minWidth: '195px',
		maxWidth: '245px',
		width: '100%'
	}
})

export default SearchMaterials;
